import styled from "styled-components";
import * as React from "react";
import OrderSummary from "./OrderSummary";

const Container = styled.div``;

const WhatsappOrder = () => {
  return (
    <Container>
      <OrderSummary />
    </Container>
  );
};

export default WhatsappOrder;
